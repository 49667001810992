import { useEffect, useState } from "react";
import req from "../../../../modules/req";


function useHolidays() {
  const [ data, setData ] = useState(undefined);
  const [ isLoading, setIsLoading ] = useState(true);

  const sync = async () => {
    setIsLoading(true);
    const res = await req("get-holidays");

    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    sync();
  }, []);

  return { data, isLoading };
}

export default useHolidays;