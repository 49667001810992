
const clsx = (...args) => args.filter(Boolean).join(" ");

const empty = (v) => {
  if (v === undefined || v === null || v === '' || v === false || v === 0 || v === '0' || v === false || (v instanceof Array && v.length === 0)) {
    return true;
  } else {
    return false;
  }
};

const switcher = (query, items = {}) => {
  if (items[ query ]) {
    return items[ query ];
  } else if (items[ "default" ]) {
    return items[ "default" ];
  } else {
    return false;
  }
};

const num = (val) => {
  return parseInt(val);
};

const flo = (val) => {
  return parseFloat(val);
}

const str = (val) => {
  return val.toString();
}

function dec(float, decimals = 2) {
  if (flo(float)) {
      if (decimals <= 0) {
          return Math.trunc(flo(float));
      } else {
          return Math.trunc(flo(float) * Math.pow(10, decimals)) / Math.pow(10, decimals);
      }
  } else {
      return float;
  }
}


export {
  clsx,
  empty,
  switcher,
  num,
  flo,
  dec
};