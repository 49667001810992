import { useMemo, useRef, useState } from "react";
import { useNotify } from "../../../components/Notify";
import { editSplit } from "../../../../components/WeGantt";
import { Flexi, Formify } from "../../../../components/WePack";
import req from "../../../../modules/req";
import Moment from "../../../../components/Moment";


const SplitEditForm = ({ task, split, setTasks, onClose, holidays, workingHours }) => {
  const { notify } = useNotify();
  
  const [ start, setStart ] = useState(split.start);
  const [ end, setEnd ] = useState(split.end);
  const [ duration, setDuration ] = useState(split.duration);

  const calculateTimeout = useRef(undefined);

  const [ min, max ] = useMemo(() => {
    const index = task.dates.findIndex(d => d.id === split.id);

    const min = task.dates[ index - 1 ]?.end ?? undefined;
    const max = task.dates[ index + 1 ]?.start ?? undefined;

    return [ min, max ];
  }, [ task, split ]);

  const reqData = useMemo(() => {
    return editSplit(
      workingHours,
      holidays,
      task,
      split,
      start,
      end,
      duration,
      duration == split.duration ? (
        "move"
      ) : (
        "resize"
      )
    );
  }, [ start, end, duration ]);



  const handleSubmit = (e) => {
    e.preventDefault();

    req("sync-task", { task: reqData }).then((res) => {
      if (res.error) {
        notify(res.error, 'Errore durante la modifica del task.', 'error');
      }
    });
    setTasks(tasks => tasks.map(t => t.id === reqData.id ? { ...reqData, moved: 1 } : t));
    onClose();
  };

  return (
    <Formify className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <Formify.Title className="w-full font-semibold border-b border-gray-400">Modifica Split</Formify.Title>
      <Flexi.Col to="5" className="space-y-1 pb-2">
        <Formify.Group>
          <Formify.Label>Data Inizio</Formify.Label>
          <Formify.Input required
            type="date"
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b"
            value={ start }
            min={ min }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Inizio', e.target.validationMessage, 'error');
            } }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setStart(val);

                calculateTimeout.current && clearTimeout(calculateTimeout.current);

                if (new Date(val) != 'Invalid Date' && new Date(val) > new Date('2000-01-01')) {
                  setEnd(Moment(val).end(duration / workingHours, holidays));
                }
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>Data Fine</Formify.Label>
          <Formify.Input required
            type="date"
            min={ start }
            max={ max }
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            value={ end }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Fine', e.target.validationMessage, 'error');
            } }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setEnd(val);
                setDuration(Moment(start).duration(val, holidays) * workingHours);
              } else {
                notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>Durata (Ore)</Formify.Label>
          <Formify.Input required
            type="number"
            min={ 0 }
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            value={ duration }
            onInput={ (val) => {
              setDuration(+val);
              setEnd(Moment(start).end(Math.ceil(+val / workingHours), holidays));
            } }
          />
        </Formify.Group>

        <Formify.Button>Applica</Formify.Button>
      </Flexi.Col>
    </Formify>
  );
};

export default SplitEditForm;