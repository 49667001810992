const DICTIONARY = {
  IT: {
    TIMESHEETPOPUP: {
      TITLE: 'Attività',
      WORKHOURS_ROW_NAME: 'Ore Lavoro',
      RESOURCES_ROW_NAME: 'Persone',
      PERSON_COL_NAME: 'Persona',
      HOURS_COL_NAME: 'Ore'
    },
    MILESTONEPOPUP: {
      MILESTONE: 'Milestone',
      TASK_NAME: 'Nome Task',
      DATE: 'Data',
      MISSING: 'Mancante',
      SUPPLIER: 'Fornitore',
      ARTICLE: 'Articolo',
      ARTICLE_CODE: 'Codice Articolo',
      ARTICLE_DESCRIPTION: 'Descrizione Articolo',
      REQUIRED_DELIVERY_DATE: 'Data Richiesta',
      CONFIRMED_DELIVERY_DATE: 'Data Confermata'
    }
  }
}

export default DICTIONARY;