import { Dialog, Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import secureLocalStorage from "react-secure-storage";

import Logo from "../assets/GT-Logo.png";
import { Fragment, useState } from "react";
import TacticalPlanning from "./apps/tactical-planning";
import Modal from "../components/Modal";
import { ButtonRemake } from "../components/Remake";
import Dashboard from "./apps/dashboard";
import { empty } from "../modules/Utils";
import config from "../assets/config.json";
import { Flaticon, Loady } from "../components/WePack";
import { useAuth } from "./components/Auth";

export const Nav = ({ nav, floatPanel, showFloatPanel, setShowFloatPanel, globalLoading }) => {
  const [ showSignOutMoadl, setShowSignOutModal ] = useState(false);
  const [ last3Active, setLast3Actives ] = useState(secureLocalStorage.getItem('last3Active') || []);
  const { userData, handleSignOut } = useAuth();

  return (
    <nav className="fixed w-full h-8 bg-white border-b border-red-600 z-40 flex top-0 right-0 shadow-sm">
      <div className="h-full px-2 flex justify-center items-center space-x-2">
        <img className="select-none w-6" draggable={ false } src={ Logo } />
        <span className="font-semibold">PDP</span>
      </div>

      <SignOutModal show={ showSignOutMoadl } onClose={ () => setShowSignOutModal(false) } onSignOut={ handleSignOut } />

      <div className="flex-1 flex items-center">
        <Transition
          show={ showFloatPanel }
          as={ Fragment }
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 translate-x-5"
          enterTo="transform opacity-100 translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100 translate-x-0"
          leaveTo="transform opacity-0 translate-x-5"
        >
          <div className="p-1 flex space-x-1">
            { floatPanel?.map((item, index) => (
              <button
                key={ index }
                className="flex space-x-1 justify-center items-center px-2 bg-white hover:bg-gray-200 rounded-md duration-75"
                onClick={ item.onClick }
              >
                { item.icon && (<Flaticon name={ item.icon.icon } type={ item.icon.type } className="text-lg" size={ 15 } />) }
                { item.label && (<span style={ { fontSize: 16 } }>{ item.label }</span>) }
              </button>
            )) }
          </div>
        </Transition>
      </div>

      <Transition
        show={ !empty(globalLoading) }
        as="div"
        className="flex items-center space-x-2 select-none"
        enter="transition ease-in duration-300"
        enterFrom="transform opacity-0 -translate-x-5"
        enterTo="transform opacity-100 translate-x-0"
        leave="transition ease-out duration-300"
        leaveFrom="transform opacity-100 translate-x-0"
        leaveTo="transform opacity-0 -translate-x-5"
      >
        <span className="text-xs">{ typeof globalLoading === 'string' && globalLoading }</span>
        <Loady
          show={ true }
          className={ "w-3 h-3" }
        />
      </Transition>

      <div className="h-full flex justify-end items-center space-x-3 px-3">
        <Menu as="div" className="relative inline-block text-left">
          <MenuButton className="w-7 h-7 hover:bg-gray-100 flex justify-center items-center bg-color rounded-full font-bold text-gray-800">
            <Flaticon name="grid" type="br" className="text-sm" />
          </MenuButton>

          <Transition
            as={ Fragment }
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >

            <MenuItems className="absolute flex flex-col right-0 mt-2 origin-top-right space-y-1 p-1 rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none">
              { nav.data.default.map((item, index) => (
                <MenuItem key={ index }>
                  { ({ active }) => (
                    <button
                      className={
                        (active && "bg-gray-300 duration-150") + " " +
                        (nav.data.active === item.id && "bg-gray-200") + " " +
                        "flex items-center space-x-1 px-1 rounded-md whitespace-nowrap select-none"
                      }
                      onClick={ () => {
                        if (item.onClick) {
                          item.onClick();
                        } else {
                          secureLocalStorage.setItem('lastActive', item.id);
                          setShowFloatPanel(false);
                          const last3Active = secureLocalStorage.getItem('last3Active');
                          if (last3Active) {
                            if (last3Active.length === 3) {
                              last3Active.shift();
                            }
                            last3Active.push(item.id);
                            secureLocalStorage.setItem('last3Active', last3Active);
                            setLast3Actives(last3Active);
                          } else {
                            secureLocalStorage.setItem('last3Active', [ item.id ]);
                            setLast3Actives([ item.id ]);
                          }
                          nav.add({ active: item.id });
                        }
                      } }
                    >
                      <div className="w-10 h-10 flex justify-center items-center rounded-md border border-red-500 bg-gray-400/40">
                        <Flaticon name={ item.icon.name } type={ item.icon.type } className="text-xl" />
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        <span className="font-semibold">{ item.name }</span>
                        <span>{ item.description }</span>
                      </div>
                    </button>
                  ) }
                </MenuItem>
              )) }
            </MenuItems>
          </Transition>
        </Menu>

        <Menu as="div" className="relative inline-block text-left">
          <MenuButton className="w-6 h-6 flex justify-center items-center bg-color rounded-full text-sm font-semibold bg-gray-300 text-gray-800 border border-red-500">
            {
              (userData.data?.NAME.slice(0, 1) ?? '') +
              (userData.data.NAME?.split(' ')[ 1 ]?.slice(0, 1) ?? '')
            }
          </MenuButton>

          <Transition
            as={ Fragment }
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute w-[250px] flex flex-col right-0 mt-2 p-1 max-w-[200px] origin-top-right rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none">
              <div className="absolute top-0 left-0 w-full h-7 bg-red-200 rounded-t-md" />
              <div className="flex z-10 flex-col justify-center items-center divide-y">
                <div className="w-full flex flex-col justify-center items-center mb-1">
                  <div className="w-12 h-12 mb-2 flex justify-center items-center shadow-md bg-color rounded-full text-xl font-semibold bg-gray-300 text-gray-800 border border-red-500">
                    { (userData.data.NAME?.slice(0, 1) ?? '') +
                      (userData.data.NAME?.split(' ')[ 1 ]?.slice(0, 1) ?? '') }
                  </div>
                  <div className="bg-gray-200 px-1 rounded-md">
                    { userData.data.USERNAME.toLowerCase() ?? '' }
                  </div>
                  <div>
                    { (userData.data.NAME?.split(' ')[ 1 ]?.slice(0, 1) ?? '') +
                      (userData.data.NAME?.split(' ')[ 1 ]?.toLowerCase().slice(1) ?? '') }
                    { (userData.data.NAME?.split(' ')[ 0 ]?.slice(0, 1) ?? '') +
                      (userData.data.NAME?.split(' ')[ 0 ]?.toLowerCase().slice(1) ?? '') }
                  </div>

                  <div className="text-xs font-thin text-gray-400">
                    Software Version { config.version }
                  </div>
                </div>
                <div className="w-full flex flex-col pt-1">
                  { nav.data.user.map((item, index) => (
                    <MenuItem key={ index }>
                      <button
                        className="duration-150 data-[active]:bg-gray-100 w-full flex items-center justify-center space-x-5 px-1 py-1 rounded-md whitespace-nowrap select-none"
                        onClick={ () => {
                          if (item.id === 'logout') {
                            setShowSignOutModal(true);
                          } else if (item.onClick) {
                            item.onClick();
                          } else {
                            secureLocalStorage.setItem('lastActive', item.id);
                            setShowFloatPanel(false);
                            nav.add({ active: item.id });
                          }
                        } }
                      >
                        <span className={ (item.style || '') }>{ item.name }</span>
                      </button>
                    </MenuItem>
                  )) }
                </div>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </nav>
  );
};

export const Main = ({ nav, setFloatPanel, globalLoading, setGlobalLoading, navigate }) => {

  return (
    <div className="h-[calc(100%-35px)] w-full bg-gray-100 mt-8 relative">
      <PageTransition show={ nav.data.active === "dashboard" } >
        <Dashboard
          setFloatPanel={ setFloatPanel }
          setGlobalLoading={ setGlobalLoading }
          globalLoading={ globalLoading }
          navigate={ navigate }
        />
      </PageTransition>

      <PageTransition show={ nav.data.active === "tactical-planning" }>
        <TacticalPlanning
          setFloatPanel={ setFloatPanel }
          setGlobalLoading={ setGlobalLoading }
          globalLoading={ globalLoading }
          navigate={ navigate }
        />
      </PageTransition>
    </div>
  );
};

const PageTransition = ({ show, children }) => {
  return (
    <Transition
      show={ show }
      as="div"
      className="w-full h-full"
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      { children }
    </Transition>
  );
};

export const SignOutModal = ({ show, onClose, onSignOut }) => {
  return (<Modal show={ show } onClose={ onClose }>
    <div className="p-5 sm:space-y-0 space-y-3">
      <div className=" flex sm:flex-row flex-col items-center sm:space-x-5 sm:space-y-0 space-y-4 text-center">
        <div>
          <div className="flex h-10 w-10 justify-center items-center rounded-full bg-blue-100">
            <Flaticon name="info" type="rr" className="text-blue-600 text-xl" />
          </div>
        </div>
        <div className="w-full space-y-2 flex flex-col items-center sm:items-start">
          <Dialog.Title className="font-semibold text-md">Attenzione</Dialog.Title>
          <p className="text-sm">Sei sicuro di voler uscire?</p>
        </div>
      </div>
      <div className="flex flex-row-reverse justify-start items-center sm:w-auto w-full">
        <ButtonRemake
          onClick={ () => { onSignOut(); onClose(); } }
          className="border w-full min-w-[60px] sm:w-auto py-1 px-2 mx-2 rounded-md shadow-sm text-sm font-semibold text-white disabled:bg-red-300 disabled:border-red-200 bg-red-600 hover:bg-red-500 border-red-500 hover:border-red-400"
        >
          Esci
        </ButtonRemake>

        <ButtonRemake
          onClick={ () => onClose() }
          className="border w-full min-w-[60px] sm:w-auto py-1 px-2 mx-2 rounded-md shadow-sm text-sm font-semibold bg-white hover:bg-gray-50 border-gray-200 hover:border-gray-300"
        >
          Chiudi
        </ButtonRemake>
      </div>
    </div>
  </Modal>);
};