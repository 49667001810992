import { Scripty } from "../../../../components/WePack";
import { useLanguage } from "../../../components/Language";


const MilestonePopup = ({ task: ms }) => {
  const { DICTIONARY: { MILESTONEPOPUP: DICT } } = useLanguage();
  const date = ms.date?.split('-').reverse().join('/');
  const reqDeliveryDate = ms.requiredDeliveryDate?.split('-').reverse().join('/');
  const confDeliveryDate = ms.confirmedDeliveryDate?.split('-').reverse().join('/');

  return ms.record == 'M' ? (
    <Scripty>
      { /** TASK MILESTONE */ }

      <Scripty.Header className="font-semibold border-b border-gray-400 text-center">
        { DICT.MILESTONE }
      </Scripty.Header>

      <Scripty.Block className="divide-y divide-gray-200 px-2 py-1">
        { /** TASK NAME */ }
        <Scripty.Block>
          <Scripty.Subtitle className="text-gray-400">
            { DICT.TASK_NAME }
          </Scripty.Subtitle>

          <Scripty.Text className="text-sm">
            { ms.name }
          </Scripty.Text>
        </Scripty.Block>

        { /** DATE */ }
        <Scripty.Block>
          <Scripty.Subtitle className="text-gray-400">
            { DICT.DATE }
          </Scripty.Subtitle>

          <Scripty.Text className="text-sm">
            { date }
          </Scripty.Text>
        </Scripty.Block>
      </Scripty.Block>
    </Scripty>
  ) : (
    <Scripty>
      {/** ODP */ }

      <Scripty.Header className="font-semibold border-b border-gray-400 text-center">
        { DICT.MILESTONE }
      </Scripty.Header>

      <Scripty.Block className="divide-y divide-gray-200 px-2 py-1">
        { /** MILESTONE SUPPLIER */ }
        { ms.supplier && (
          <Scripty.Block>
            <Scripty.Subtitle className="text-gray-400">
              { DICT.SUPPLIER }
            </Scripty.Subtitle>

            <Scripty.Text className="text-sm">
              { ms.supplier }
            </Scripty.Text>
          </Scripty.Block>
        ) }
        
        { /** MILESTONE ARTICLE NUMBER */ }
        <Scripty.Block>
          <Scripty.Subtitle className="text-gray-400">
            { DICT.ARTICLE }
          </Scripty.Subtitle>

          <Scripty.Text className="text-sm">
            ({ ms.articleCode }) { ms.articleDescription }
          </Scripty.Text>
        </Scripty.Block>

        { /** MILESTONE REQUIRED DELIVERY DATE */ }
        <Scripty.Block>
          <Scripty.Subtitle className="text-gray-400">
            { DICT.REQUIRED_DELIVERY_DATE }
          </Scripty.Subtitle>

          <Scripty.Text className="text-sm">
            { reqDeliveryDate }
          </Scripty.Text>
        </Scripty.Block>

        { /** MILESTONE CONFIRMED DELIVERY DATE */ }
        { ms.confirmedDeliveryDate && (
          <Scripty.Block>
            <Scripty.Subtitle className="text-gray-400">
              { DICT.CONFIRMED_DELIVERY_DATE }
            </Scripty.Subtitle>

            <Scripty.Text className="text-sm">
              { confDeliveryDate }
            </Scripty.Text>
          </Scripty.Block>
        ) }
      </Scripty.Block>
    </Scripty>
  );
};

export default MilestonePopup;