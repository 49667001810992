

const TimesheetCell = ({ sizeMultipler, hours, fontSize }) => {
  return (
    <span className="font-bold" style={ { fontSize: fontSize * (sizeMultipler) * 0.8 } }>
      {
        !Number.isInteger(Number(hours)) ? (
          Math.floor(hours) + '.'
        ) : (
          hours
        )
      }
    </span>
  );
};

export default TimesheetCell;