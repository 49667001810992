
import { Dialog, DialogPanel, Disclosure, RadioGroup, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { clsx } from "../modules/Utils";
import LoadingAnimation from "../assets/Rolling-1.3s-200px.svg";

const Flaticon = ({ name, type, size, className }) => {
  return (
    <i
      className={ clsx(
        className,
        "flex justify-center items-center fi",
        "fi-" + (type ?? "bs") + "-" + (name ?? 'question')
      ) }
      style={ { fontSize: size ?? "" } }
    />
  );
};

const Contexture = ({ children }) => {
  const [ show, setShow ] = useState(false);

  return (
    <div className="relative">
      { children({ show, setShow }) }
    </div>
  );
};

const ContextureSwitch = ({ onClick, className, children }) => {
  return (
    <button
      onClick={ onClick }
      className={ className }
    >
      { children }
    </button>
  );
};

const ContexturePanel = ({ show, onClose, position, children, addClasses, className, simple,
  enter, enterFrom, enterTo, leave, leaveFrom, leaveTo, outClose }) => {
  const DefaultClassName = clsx(
    "w-full border focus:outline-none border-gray-300 bg-white rounded-sm",
    "shadow-lg flex flex-col items-stretch justify-center p-1 min-w-36",
    addClasses
  );

  const firstEnter = useRef(false);

  const isFirstOpen = useRef(true);

  const handleMouseEnter = () => {
    if (!firstEnter.current) {
      firstEnter.current = true;
    }
  };

  useEffect(() => {
    if (!show) {
      isFirstOpen.current = true;
      return;
    }

    const handleClickOutside = (e) => {
      if (e.target.closest('.contexture') || e.target.classList.contains('contexture')) return;
      if (isFirstOpen.current) return;

      isFirstOpen.current = true;
      firstEnter.current = false;

      onClose();
    };

    window.addEventListener('click', handleClickOutside);

    setTimeout(() => isFirstOpen.current = false, 50);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [ show ]);

  const handleMouseLeave = () => {
    if (firstEnter.current && outClose) {
      firstEnter.current = false;
      isFirstOpen.current = true;
      onClose();
    }
  };

  if (simple) {
    if (position && position.x && position.y) {
      return (
        <Transition
          as="div"
          show={ show }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
          className={ clsx(
            "fixed z-50 text-nowrap outline-none contexture",
            (position.y > window.innerHeight * 0.75) && "-translate-y-full",
            (position.x > (window.innerWidth * 0.75)) && "-translate-x-full",
          ) }
          style={ { left: position.x, top: position.y } }
        >
          { position.y > window.innerHeight * 0.75 && position.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position.y > window.innerHeight * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) }
        </Transition>
      );
    } else {
      return (
        <Transition
          as="div"
          show={ show }
          className={ clsx(className ?? DefaultClassName, "fixed z-50 outline-none contexture") }
          enter={ enter ?? "transition transform duration-100 ease-out" }
          enterFrom={ enterFrom ?? "opacity-0 scale-0" }
          enterTo={ enterTo ?? "opacity-100 scale-100" }
          leave={ leave ?? "transition transform duration-100 ease-in" }
          leaveFrom={ leaveFrom ?? "opacity-100 scale-100" }
          leaveTo={ leaveTo ?? "opacity-0 scale-0" }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
        >
          { children }
        </Transition>
      );
    }
  } else {
    return (
      <Transition
        as={ Dialog }
        onClose={ onClose }
        show={ show }
        className={ clsx(
          "fixed z-50 text-nowrap outline-none",
          (position.y > window.innerHeight * 0.75) && "-translate-y-full",
          (position.x > (window.innerWidth * 0.75)) && "-translate-x-full",
        ) }
        style={ { left: position.x, top: position.y } }
      >
        { (position.y > window.innerHeight * 0.75 && position.x > window.innerWidth * 0.75) ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position.y > window.innerHeight * 0.75 ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position.x > window.innerWidth * 0.75 ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) }
      </Transition>
    );
  }
};

const ContextureCategory = ({ children, addClasses, label, ...props }) => {
  return (
    <div
      { ...props }
      className={ clsx(
        "border-t mt-2 pt-2.5 w-full flex flex-col items-stretch justify-center relative",
        addClasses
      ) }
    >
      { label && (
        <div className="text-sm absolute bg-white top-0 left-[1px] px-2 -translate-y-1/2 text-gray-600">
          { label }
        </div>
      ) }
      { children }
    </div>
  );
};

const ContextureButton = ({ children, addClasses, className, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <button
      type="button"
      className={ className ?? clsx(
        "flex justify-start items-center px-3 py-0.5 w-full rounded-sm",
        "space-x-2 text-nowrap hover:bg-gray-200",
        addClasses
      ) }
      onClick={ onClick }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    >
      { children }
    </button>
  );
};

const Modality = ({ show, onClose, children, className }) => {
  return (
    <Transition
      className="fixed z-[99]"
      show={ show }
      onClose={ onClose }
    >
      <Dialog>
        <TransitionChild
          as={ Fragment }
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={ Fragment }
              enter="ease-out duration-150"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={ clsx(
                  "w-full max-w-md transform overflow-hidden p-6 text-left align-middle transition-all",
                  className ?? "bg-white rounded-2xl shadow-xl"
                ) }
              >
                { children }
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

Modality.Header = ({ children, className }) => {
  return (
    <Dialog.Title
      as="h3"
      className={ clsx(
        "mb-2",
        className ?? "font-medium text-gray-900"
      ) }
    >
      { children }
    </Dialog.Title>
  );
};

Modality.Body = ({ children, className }) => {
  return (
    <Dialog.Description
      className={ clsx("mb-4", className ?? "text-sm text-gray-500") }
    >
      { children }
    </Dialog.Description>
  );
};

Modality.Footer = ({ children, className }) => {
  return (
    <div className={ clsx("flex justify-end space-x-2", className) }>
      { children }
    </div>
  );
};

Modality.Button = ({ children, className, ...props }) => {
  return (
    <button
      { ...props }
      className={ clsx(
        "w-full sm:w-auto sm:min-w-[60px] flex justify-center items-center border py-1 px-2 text-sm font-semibold",
        className ?? "text-white disabled:bg-red-300 disabled:border-red-200 bg-red-600 hover:bg-red-500 border-red-500 hover:border-red-400 rounded-md shadow-sm"
      ) }
    >
      { children }
    </button>
  );
};

const Popcraft = ({ show, children, className, posX, posY }) => {

  return (
    <Transition
      as={ Fragment }
      show={ show }
      className="fixed z-50"
      style={ { top: posY, left: posX } }
    >
      <Transition.Child
        as={ Fragment }
        enter="transition ease-out duration-75 origin-top"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75 origin-top"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={ className ?? "min-w-[220px] bg-white rounded-sm shadow-lg border border-gray-300" } >
          { children }
        </div>
      </Transition.Child>
    </Transition>
  );
};

const Revalio = ({ children, ...props }) => {
  return (
    <Disclosure { ...props }>
      { children }
    </Disclosure>
  );
};

Revalio.Button = ({ children, className, ...props }) => {
  return (
    <Disclosure.Button
      { ...props }
      className={ className ?? "flex w-full justify-between select-none duration-75 px-4 py-2 text-left rounded-sm bg-blue-100 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75" }
    >
      { children }
    </Disclosure.Button>
  );
};

Revalio.ButtonWithIcon = ({ icon, label, open }) => {
  return (
    <Disclosure.Button
      className={ "flex w-full justify-between select-none duration-75 px-4 py-2 text-left rounded-sm bg-blue-100 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75" }
    >
      <span className="space-x-2 flex items-center">
        <Flaticon className="w-2 h-2" name={ icon } type="rr" />
        <p>{ label }</p>
      </span>
      <Flaticon
        className={ clsx("h-5 w-5 text-blue-500", open ? 'rotate-180 transform' : '') }
        name="angle-small-down"
        type="rr"
      />
    </Disclosure.Button>
  );
};

Revalio.Panel = ({ children, ...props }) => {
  return (
    <Disclosure.Panel { ...props }>
      { children }
    </Disclosure.Panel>
  );
};

const Radiant = ({ children, ...props }) => {
  return (
    <RadioGroup { ...props }>
      { children }
    </RadioGroup>
  );
};

Radiant.Option = ({ children, ...props }) => {
  return (
    <RadioGroup.Option { ...props }>
      { children }
    </RadioGroup.Option>
  );
};

Radiant.Label = ({ children, ...props }) => {
  return (
    <RadioGroup.Label { ...props }>
      { children }
    </RadioGroup.Label>
  );
};

Radiant.Description = ({ children, ...props }) => {
  return (
    <RadioGroup.Description { ...props }>
      { children }
    </RadioGroup.Description>
  );
};

const Animo = ({ children, ...props }) => {
  return (
    <Transition { ...props }>
      { children }
    </Transition>
  );
};

Animo.Fade = ({ children, ...props }) => {
  return (
    <TransitionChild
      { ...props }
      as={ Fragment }
      enter="transition duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      { children }
    </TransitionChild>
  );
};

Animo.FadeZoom = ({ children, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      enter="transition duration-300 transform"
      enterFrom="scale-75 opacity-0"
      enterTo="scale-100 opacity-100"
      leave="transition duration-200 transform"
      leaveFrom="scale-100 opacity-100"
      leaveTo="scale-75 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeDown = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="translate-y-4 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="translate-y-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeUp = ({ children, className, ...props }) => {
  return (
    <TransitionChild
      { ...props }
      className={ clsx(className ?? "duration-75", "transition transform") }
      enter="easy-out"
      enterFrom="-translate-y-4 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="easy-in"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="-translate-y-4 opacity-0"
    >
      { children }
    </TransitionChild>
  );
};

Animo.FadeRight = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="translate-x-4 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-x-0 opacity-100"
      leaveTo="translate-x-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeLeft = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="-translate-x-4 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-x-0 opacity-100"
      leaveTo="-translate-x-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeLeftUp = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="-translate-x-4 -translate-y-4 opacity-0"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-x-0 translate-y-0 opacity-100"
      leaveTo="-translate-x-4 -translate-y-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeRightUp = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="translate-x-4 -translate-y-4 opacity-0"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-x-0 translate-y-0 opacity-100"
      leaveTo="translate-x-4 -translate-y-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeRightDown = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="translate-x-4 translate-y-4 opacity-0"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-x-0 translate-y-0 opacity-100"
      leaveTo="translate-x-4 translate-y-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

Animo.FadeLeftDown = ({ children, className, ...props }) => {
  return (
    <Transition.Child
      { ...props }
      className={ className ?? "duration-75" }
      enter="transition transform ease-out"
      enterFrom="-translate-x-4 translate-y-4 opacity-0"
      enterTo="translate-x-0 translate-y-0 opacity-100"
      leave="transition transform ease-in"
      leaveFrom="translate-x-0 translate-y-0 opacity-100"
      leaveTo="-translate-x-4 translate-y-4 opacity-0"
    >
      { children }
    </Transition.Child>
  );
};

const Formify = ({ children, ...props }) => {
  return (<form { ...props }>{ children }</form>);
};

Formify.Label = ({ children, ...props }) => {
  return (<label { ...props }>{ children }</label>);
};

Formify.Textarea = ({ onInput, ...props }) => {
  return (<textarea { ...props } onInput={ (e) => onInput(e.target.value) } />);
};

Formify.Select = ({ onInput, value, ...props }) => {
  return (<select { ...props } value={ value } onChange={ (e) => onInput(e.target.value) } />);
};

Formify.Option = ({ children, value, ...props }) => {
  return (<option { ...props } value={ value }>{ children }</option>);
};

Formify.Group = ({ children, className, ...props }) => {
  return (<div { ...props } className={ className ?? "flex flex-col w-full" }>{ children }</div>);
};

Formify.Button = ({ children, className, onClick, ...props }) => {
  return (
    <button
      { ...props }
      onClick={ onClick }
      className={ className ?? "border disabled:bg-green-300 disabled:border-green-200 rounded-sm px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400 hover:border-green-300 text-white" }
    >
      { children }
    </button>
  );
};

Formify.Input = ({ onInput, onCheck, onChange, onBlur, ...props }) => {
  return (
    <input
      { ...props }
      onInput={ (e) => {
        e.preventDefault();
        onInput && onInput(e.target.value);
      } }
      onBlur={ (e) => {
        e.preventDefault();
        onBlur && onBlur(e.target.value);
      } }
      onChange={ (e) => {
        e.preventDefault();
        onChange && onChange(e.target.value);
        onCheck && onCheck(e.target.checked);
      } }
    />
  );
};

Formify.Title = ({ children, className, ...props }) => {
  return (
    <h1
      { ...props }
      className={ className ?? "mb-4 font-semibold text-lg" }
    >
      { children }
    </h1>
  );
};

const FlexiAlignCol = {
  "ss": "justify-start items-start",
  "sc": "justify-start items-center",
  "se": "justify-start items-end",
  "cs": "justify-center items-start",
  "cc": "justify-center items-center",
  "ce": "justify-center items-end",
  "es": "justify-end items-start",
  "ec": "justify-end items-center",
  "ee": "justify-end items-end",
  1: "justify-start items-start",
  2: "justify-start items-center",
  3: "justify-start items-end",
  4: "justify-center items-start",
  5: "justify-center items-center",
  6: "justify-center items-end",
  7: "justify-end items-start",
  8: "justify-end items-center",
  9: "justify-end items-end",
};

const FlexiAlignRow = {
  "ss": "justify-start items-start",
  "sc": "justify-center items-start",
  "se": "justify-end items-start",
  "cs": "justify-start items-center",
  "cc": "justify-center items-center",
  "ce": "justify-end items-center",
  "es": "justify-start items-end",
  "ec": "justify-center items-end",
  "ee": "justify-end items-end",

  "bs": "justify-between items-start",
  "bc": "justify-between items-center",
  "be": "justify-between items-end",

  "as": "justify-around items-start",
  "ac": "justify-around items-center",
  "ae": "justify-around items-end",

  1: "justify-start items-start",
  2: "justify-center items-start",
  3: "justify-end items-start",
  4: "justify-start items-center",
  5: "justify-center items-center",
  6: "justify-end items-center",
  7: "justify-start items-end",
  8: "justify-center items-end",
  9: "justify-end items-end"
};

const Flexi = ({ children, className }) => {
  return (<div className={ clsx("flex", className) } >{ children }</div>);
};

Flexi.Col = ({ children, className, to }) => {
  return (<div className={ clsx("flex flex-col", FlexiAlignCol[ to ], className) }>{ children }</div>);
};

Flexi.Row = ({ children, className, to }) => {
  return (<div className={ clsx("flex flex-row", FlexiAlignRow[ to ], className) }>{ children }</div>);
};

const Scripty = ({ children, className, ...props }) => {
  return (
    <div { ...props } className={ clsx("flex flex-col", className) }>
      { children }
    </div>
  );
};

Scripty.Header = ({ children, className }) => {
  return (
    <h1 className={ className ?? "text-xl font-semibold flex justify-center w-full text-center" }>
      { children }
    </h1>
  );
};

Scripty.Block = ({ children, className }) => {
  return (
    <div className={ clsx("flex flex-col justify-center items-center w-full", className) }>
      { children }
    </div>
  );
};

Scripty.Subtitle = ({ children, className }) => {
  return (
    <h2 className={ clsx("mb-1 flex justify-center w-full text-center", className ?? "text-base text-gray-800") }>
      { children }
    </h2>
  );
};

Scripty.Text = ({ children, className }) => {
  return (
    <p className={ clsx(className ?? "text-gray-600 text-sm") }>
      { children }
    </p>
  );
};

const ReactioAnchors = {
  "ss": "translate(0, 0)",
  "sc": "translate(-50%, 0)",
  "se": "translate(-100%, 0)",
  "cs": "translate(0, -50%)",
  "cc": "translate(-50%, -50%)",
  "ce": "translate(-100%, -50%)",
  "es": "translate(0, -100%)",
  "ec": "translate(-50%, -100%)",
  "ee": "translate(-100%, -100%)",
  1: "translate(0, 0)",
  2: "translate(-50%, 0)",
  3: "translate(-100%, 0)",
  4: "translate(0, -50%)",
  5: "translate(-50%, -50%)",
  6: "translate(-100%, -50%)",
  7: "translate(0, -100%)",
  8: "translate(-50%, -100%)",
  9: "translate(-100%, -100%)"
};

const Rectio = ({ w, h, x, y, r, className, a, style }) => {
  return (
    <div
      className={ className }
      style={ { ...style, width: w, height: h, left: x, top: y, transform: "rotate(" + (r ?? "0deg") + ") " + ReactioAnchors[ a ] ?? "translate(0, 0)" } }
    />
  );
};

Rectio.Square = ({ s, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute", className ?? "bg-gray-300") }
      w={ s }
      h={ s }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

Rectio.Circle = ({ size, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute rounded-full", className ?? "bg-gray-300") }
      w={ size }
      h={ size }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

Rectio.Rectangle = ({ w, h, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute", className ?? "bg-gray-300") }
      w={ w }
      h={ h }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

Rectio.Triangle = ({ size, x, y, r, a, className }) => {
  return (
    <Rectio
      className={ clsx("absolute", className ?? "bg-gray-300") }
      style={ { borderLeft: size + "px solid transparent", borderRight: size + "px solid transparent", borderBottom: size + "px solid currentColor" } }
      w={ 0 }
      h={ 0 }
      x={ x }
      y={ y }
      r={ r }
      a={ a }
    />
  );
};

const Listify = ({ children, className }) => {
  return (
    <ul className={ clsx("list-disc list-inside w-full", className) }>
      { children }
    </ul>
  );
};

Listify.Item = ({ children, className }) => {
  return (
    <li className={ className }>
      { children }
    </li>
  );
};


const Tablify = ({ children, ...props }) => {
  return (
    <table { ...props }>
      { children }
    </table>
  );
};

Tablify.Head = ({ children, ...props }) => {
  return (
    <thead { ...props }>
      { children }
    </thead>
  );
};

Tablify.Body = ({ children, ...props }) => {
  return (
    <tbody { ...props }>
      { children }
    </tbody>
  );
};

Tablify.Row = ({ children, ...props }) => {
  return (
    <tr { ...props }>
      { children }
    </tr>
  );
};

Tablify.Cell = ({ children, ...props }) => {
  return (
    <td { ...props }>
      { children }
    </td>
  );
};

Tablify.HeadCell = ({ children, ...props }) => {
  return (
    <th { ...props }>
      { children }
    </th>
  );
};

const Loady = ({ show, children, color, size, className, label }) => {
  return show ? (
    <div className={ "flex flex-col justify-center items-center " + className }>
      { label && <div className="text-2xl font-bold my-4" style={ { color } }>{ label }</div> }
      <img src={ LoadingAnimation } alt="Loading..." style={ { width: size, height: size, color } } />
    </div>
  ) : (
    children
  );
};

export {
  Contexture,
  ContextureSwitch,
  ContexturePanel,
  ContextureCategory,
  ContextureButton,
  Modality,
  Popcraft,
  Revalio,
  Radiant,
  Animo,
  Formify,
  Flexi,
  Scripty,
  Rectio,
  Listify,
  Tablify,
  Loady,
  Flaticon
};