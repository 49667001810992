import { Listify, Scripty } from "../../../../components/WePack";
import { useLanguage } from "../../../components/Language";

const TimesheetPopup = ({ date, amountOfResources, amountOfHours, resources }) => {
  const { DICTIONARY: { TIMESHEETPOPUP: DICT } } = useLanguage();

  return (<>
    <Scripty>
      <Scripty.Header className="font-semibold w-full border-b border-gray-400 flex justify-center">{ DICT.TITLE }</Scripty.Header>
      <Scripty.Block>
        <Scripty.Text className="w-full flex justify-center items-center">
          { date }
        </Scripty.Text>

        <Listify className="divide-y divide-gray-300 border-b-2 border-gray-300 px-1">
          <Listify.Item className="flex justify-between px-1">
            <Scripty.Text className="text-base">{ DICT.WORKHOURS_ROW_NAME }</Scripty.Text>
            <Scripty.Text className="text-base">{ amountOfHours }</Scripty.Text>
          </Listify.Item>
          <Listify.Item className="flex items-center px-1 justify-between">
            <Scripty.Text className="text-base">{ DICT.RESOURCES_ROW_NAME }</Scripty.Text>
            <Scripty.Text className="text-base">{ amountOfResources }</Scripty.Text>
          </Listify.Item>
        </Listify>

        <Listify className="border-b border-gray-300 divide-y divide-gray-300 px-1 w-full">
          <Listify.Item className="flex items-center px-1 justify-between">
            <Scripty.Text className="font-semibold">{ DICT.PERSON_COL_NAME } </Scripty.Text>
            <Scripty.Text className="font-semibold">{ DICT.HOURS_COL_NAME }</Scripty.Text>
          </Listify.Item>
          { resources?.map((detail) => {
            const resHours = detail.hours;
            const resName = detail.resource.split(' ').map((name) => name.slice(0, 1) + name.toLowerCase().slice(1)).join(' ');

            return (
              <Listify.Item key={ date + resName } className="flex items-center px-1 justify-between">
                <Scripty.Text className="flex-1">{ resName }</Scripty.Text>
                <Scripty.Text>{ resHours }</Scripty.Text>
              </Listify.Item>
            );
          }) }
        </Listify>
      </Scripty.Block>
    </Scripty>
  </>);
};

export default TimesheetPopup;