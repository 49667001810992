import { Transition } from "@headlessui/react";
import secureLocalStorage from "react-secure-storage";

import { useStateRemake } from "../components/Remake";
import { Main, Nav } from "./components";
import { useEffect, useRef, useState } from "react";
import { clsx, empty, switcher } from "../modules/Utils";
import { Flaticon } from "../components/WePack";
import Auth, { useAuth } from "./components/Auth";
import Notify, { useNotify } from "./components/Notify";
import ContextMenu from "../components/ContextMenu";

export default function App() {
	const { authStatus } = useAuth();

	const [ floatPanel, setFloatPanel ] = useState([]);
	const [ showFloatPanel, setShowFloatPanel ] = useState(false);

	const [ globalLoading, setGlobalLoading ] = useState(false);
	const nav = useStateRemake({
		user: [
			{ id: 'logout', name: 'Log Out', style: 'text-red-600', icon: { type: 'rr', name: 'exit' } }
		],
		default: [
			{ id: 'dashboard', name: 'Dashboard', description: 'Statistiche sintetiche', icon: { type: 'rr', name: 'home' } },
			{ id: 'tactical-planning', name: 'Tactical Planning', description: 'Pianifica le risorse', icon: { type: 'rr', name: 'chart-gantt' } }
		],
		active: secureLocalStorage.getItem('lastActive') || 'dashboard',
		params: {}
	});

	const navigate = (id, params) => {
		nav.set({ ...nav.data, id, params });
	};

	return (<>
		<Notify />

		<ContextMenu />

		<Transition
			show={ authStatus === 'success' }
			as="div"
			className="w-full h-full"
		>
			<Nav
				nav={ nav }
				floatPanel={ floatPanel }
				showFloatPanel={ showFloatPanel }
				setShowFloatPanel={ setShowFloatPanel }
				globalLoading={ globalLoading }
			/>

			<Main
				nav={ nav }
				setFloatPanel={ (data) => {
					setTimeout(() => {
						setFloatPanel(data);
						setShowFloatPanel(true);
					}, 500);
				} }
				setGlobalLoading={ setGlobalLoading }
				globalLoading={ !empty(globalLoading) }
				navigate={ navigate }
			/>
		</Transition>

		<Transition
			show={ authStatus === 'sign-in-required' }
			as="div"
			className={ "w-full h-full z-40" }
			enter="ease-out duration-300"
			enterFrom="opacity-0 absolute -top-1/4 z-10"
			enterTo="opacity-100 absolute top-0 z-10"
			leave="ease-in duration-300"
			leaveFrom="opacity-100 absolute top-0 z-10"
			leaveTo="opacity-0 absolute -top-1/4 z-10"
		>
			<Auth />
		</Transition>
	</>);
}