import { useEffect, useMemo, useState } from "react";
import { useNotify } from "../../../components/Notify";
import req from "../../../../modules/req";
import { Flexi, Formify } from "../../../../components/WePack";


const MilestoneFormEdit = ({ task, setTasks, holidays, setGlobalLoading, onClose, workingHours }) => {
  const { notify } = useNotify();

  const [ start, setStart ] = useState(task.start);
  const [ label, setLabel ] = useState(task.name == task.origin ? '' : task.name);

  useEffect(() => {
    setStart(task.start);
    setLabel(task.name == task.origin ? '' : task.name);
  }, [ task ]);

  const reqData = useMemo(() => {
    const data = { ...task };
    data.label = label;
    data.start = start;
    data.date = start;

    return data;
  }, [ start, label ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (holidays.includes(start)) {
      notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
      return;
    }

    setGlobalLoading("Aggiornamento Task...");

    req('sync-task', { task: reqData }).then(async (res) => {
      if (res.success) {
        setTasks(tasks => tasks.map(t => t.id == task.id ? { ...reqData, moved: 1, name: reqData.label || task.origin } : t));
      } else {
        notify(res.error, "Errore durante l'aggiornamento del task.", 'error');
      }

      setGlobalLoading(false);
    });

    onClose();
  };

  return (
    <Formify className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <Formify.Title className="w-full font-semibold border-b border-gray-400">Modifica Task</Formify.Title>

      <Flexi.Col to="5" className="space-y-1 pb-2">
        <Formify.Group>
          <Formify.Label>Titolo</Formify.Label>
          <Formify.Input
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            value={ label }
            maxLength={ 50 }
            onInput={ (val) => task.record == 'M' && setLabel(val) }
            placeholder={ task.origin }
            disabled={ task.record != "M" }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>Data</Formify.Label>
          <Formify.Input required
            type="date"
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b"
            value={ start }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setStart(val);
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </Formify.Group>

        <Formify.Button>Salva</Formify.Button>
      </Flexi.Col>
    </Formify>
  );
};

export default MilestoneFormEdit;