import { Dialog, Transition } from "@headlessui/react";
import { ContextureButton, ContextureCategory, ContexturePanel, Flaticon, Flexi, Formify, Radiant, Revalio } from "../../../../components/WePack";
import { Fragment, useEffect, useRef, useState } from "react";
import { clsx } from "../../../../modules/Utils";

const POS_X = 245;
const POS_Y = 40;

const SettingsPanel = ({ show, onClose, fontSize, cellHeight, cellWidth, setParameter, parameters }) => {
  const timeoutSizeMultipler = useRef(undefined);
  const [ showSizeMultipler, setShowSizeMultipler ] = useState(false);

  const multiplerOptions = [
    { value: 0.7, label: 'Molto Piccolo' },
    { value: 0.8, label: 'Piccolo' },
    { value: 0.9, label: 'Medio' },
    { value: 1, label: 'Normale' }
  ];

  const [ querySizeMultipler, setQuerySizeMultipler ] = useState(Number(parameters?.sizeMultipler) || 0.9);
  const [ queryTodayColor, setQueryTodayColor ] = useState(parameters?.todayColor || "#ff0000");
  const [ queryHolidayColor, setQueryHolidayColor ] = useState(parameters?.holidayColor || '#f0f0f0');
  const [ queryCommessaTaskColor, setQueryCommessaTaskColor ] = useState(parameters?.commessaTaskColor || '#3b82f6');
  const [ queryFaseTaskColor, setQueryFaseTaskColor ] = useState(parameters?.faseTaskColor || '#22c55e');
  const [ queryGRPModificabileTaskColor, setQueryGRPModificabileTaskColor ] = useState(parameters?.grpModificabileTaskColor || '#22c55e');
  const [ queryTimesheetLowColor, setQueryTimesheetLowColor ] = useState(parameters?.timesheetLowColor || '#d1d5db');
  const [ queryTimesheetMediumColor, setQueryTimesheetMediumColor ] = useState(parameters?.timesheetMediumColor || '#9ca3af');
  const [ queryTimesheetHighColor, setQueryTimesheetHighColor ] = useState(parameters?.timesheetHighColor || '#6b7280');
  const [ queryCellHeight, setQueryCellHeight ] = useState(parameters?.cellHeight || cellHeight);

  useEffect(() => {
    setQuerySizeMultipler(+parameters?.sizeMultipler || 0.9);
    setQueryTodayColor(parameters?.todayColor || "#ff0000");
    setQueryHolidayColor(parameters?.holidayColor || '#f0f0f0');
    setQueryCommessaTaskColor(parameters?.commessaTaskColor || '#3b82f6');
    setQueryFaseTaskColor(parameters?.faseTaskColor || '#22c55e');
    setQueryGRPModificabileTaskColor(parameters?.grpModificabileTaskColor || '#22c55e');
    setQueryTimesheetLowColor(parameters?.timesheetLowColor || '#d1d5db');
    setQueryTimesheetMediumColor(parameters?.timesheetMediumColor || '#9ca3af');
    setQueryTimesheetHighColor(parameters?.timesheetHighColor || '#6b7280');
    setQueryCellHeight(parameters?.cellHeight || cellHeight);
  }, [ parameters, show ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setParameter('sizeMultipler', querySizeMultipler);
    setParameter('todayColor', queryTodayColor);
    setParameter('holidayColor', queryHolidayColor);
    setParameter('commessaTaskColor', queryCommessaTaskColor);
    setParameter('faseTaskColor', queryFaseTaskColor);
    setParameter('grpModificabileTaskColor', queryGRPModificabileTaskColor);
    setParameter('timesheetLowColor', queryTimesheetLowColor);
    setParameter('timesheetMediumColor', queryTimesheetMediumColor);
    setParameter('timesheetHighColor', queryTimesheetHighColor);
    setParameter('cellHeight', queryCellHeight);
    onClose();
  };

  const handleClose = () => {
    if (timeoutSizeMultipler.current) {
      clearTimeout(timeoutSizeMultipler.current);
    }

    setShowSizeMultipler(false);
    onClose();
  };

  return (
    <ContexturePanel simple
      onClose={ handleClose }
      show={ show }
      position={ { x: POS_X, y: POS_Y } }
    >
      <ContextureCategory label="Dimensioni">
        <ContextureButton
          onMouseEvent={ () => () => {
            if (timeoutSizeMultipler.current) {
              clearTimeout(timeoutSizeMultipler.current);
            };

            timeoutSizeMultipler.current = setTimeout(() => {
              setShowSizeMultipler(true);
            }, 500);
          } }
          onMouseLeave={ () => {
            if (timeoutSizeMultipler.current) {
              clearTimeout(timeoutSizeMultipler.current);
            }
          } }
          onClick={ () => {
            if (timeoutSizeMultipler.current) {
              clearTimeout(timeoutSizeMultipler.current);
            }

            setShowSizeMultipler(true);
          } }
        >
          <Flaticon className="w-4 h-4" type="rr" name="text-size" />

          <span className="text-gray-800 text-start flex-1">
            Moltiplicatore
          </span>

          <Flaticon name="angle-right" type="rr" size={ 11 } />
        </ContextureButton>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <Flaticon name="text-height" className="w-4 h-4" type="rr" />

          <span className="text-gray-800 text-start flex-1 select-none">
            L'altezza righe
          </span>

          <div>
            <Formify.Input
              className="pr-0 pl-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b w-16"
              type="number"
              value={ queryCellHeight }
              onInput={ (val) => setQueryCellHeight(val) }
            />
          </div>
        </div>
      </ContextureCategory>

      <ContextureCategory label="Colori">
        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Oggi
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryTodayColor }
            onInput={ (val) => setQueryTodayColor(val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Giorno festivo
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryHolidayColor }
            onInput={ (val) => setQueryHolidayColor(val) }
          />
        </div>

        <div
          className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap"

        >
          <span className="text-gray-800 text-start flex-1 select-none">
            Commessa
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryCommessaTaskColor }
            onInput={ (val) => setQueryCommessaTaskColor(val) }
          />
        </div>

        <div
          className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap"

        >
          <span className="text-gray-800 text-start flex-1 select-none">
            Fase
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryFaseTaskColor }
            onInput={ (val) => setQueryFaseTaskColor(val) }
          />
        </div>

        <div
          className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap"

        >
          <span className="text-gray-800 text-start flex-1 select-none">
            Sotto-Fase
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryGRPModificabileTaskColor }
            onInput={ (val) => setQueryGRPModificabileTaskColor(val) }
          />
        </div>

        <div
          className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap"

        >
          <span className="text-gray-800 text-start flex-1 select-none">
            Attività [=1]
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryTimesheetLowColor }
            onInput={ (val) => setQueryTimesheetLowColor(val) }
          />
        </div>

        <div
          className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap"

        >
          <span className="text-gray-800 text-start flex-1 select-none">
            Attività [=2]
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryTimesheetMediumColor }
            onInput={ (val) => setQueryTimesheetMediumColor(val) }
          />
        </div>

        <div
          className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap"

        >
          <span className="text-gray-800 text-start flex-1 select-none">
            Attività [≥3]
          </span>

          <Formify.Input
            className="p-0 m-0 w-12 h-6"
            type="color"
            value={ queryTimesheetHighColor }
            onInput={ (val) => setQueryTimesheetHighColor(val) }
          />
        </div>
      </ContextureCategory>

      <div className="flex items-center justify-end w-full pt-2">
        <button
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-2 duration-75 bg-green-500 hover:bg-green-400",
            "border-green-400 hover:border-green-300 text-white"
          ) }
          onClick={ handleSubmit }
        >
          Applica
        </button>
      </div>

      <ContexturePanel simple outClose
        show={ showSizeMultipler }
        position={ { x: POS_X + 210, y: POS_Y + 30 } }
        onClose={ () => setShowSizeMultipler(false) }
      >
        <Radiant value={ querySizeMultipler } onChange={ setQuerySizeMultipler }>
          { multiplerOptions.map((opt) => (
            <Radiant.Option
              key={ opt.value }
              value={ opt.value }
            >
              { ({ checked }) => (
                <ContextureButton
                  addClasses={ checked && "bg-green-200 hover:bg-green-200" }
                >
                  <Flaticon name="check" type="rr" className={ clsx("h-4 w-4", !checked && "invisible") } />

                  <div className="flex space-x-2">
                    <h1 className="w-24 text-start">
                      { opt.label }
                    </h1>

                    <div className="w-5">
                      <div
                        className="bg-white/60 border border-gray-300"
                        style={ { width: cellWidth * opt.value, height: cellHeight * opt.value } }
                      />
                    </div>

                    <div style={ { fontSize: fontSize * opt.value } }>
                      { new Date().toISOString().split('T')[ 0 ].split('-').reverse().join('/') }
                    </div>
                  </div>
                </ContextureButton>
              ) }
            </Radiant.Option>
          )) }
        </Radiant>
      </ContexturePanel>
    </ContexturePanel>
  );
};

export default SettingsPanel;