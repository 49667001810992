import { useEffect, useState } from "react";
import { useNotify } from "../../../components/Notify";
import req from "../../../../modules/req";
import { Flexi, Formify } from "../../../../components/WePack";
import Moment from "../../../../components/Moment";


const TaskFormEdit = ({ task, setTasks, holidays, setGlobalLoading, onClose, workingHours }) => {
  const { notify } = useNotify();

  const [ resources, setResources ] = useState(+task.resources);
  const [ start, setStart ] = useState(task.start);
  const [ duration, setDuration ] = useState(+task.duration);
  const [ end, setEnd ] = useState(task.end);
  const [ label, setLabel ] = useState(task.name == task.origin ? '' : task.name);

  const [ reqData, setReqData ] = useState(task);

  useEffect(() => {
    setResources(+task.resources);
    setStart(task.start);
    setDuration(+task.duration);
    setEnd(task.end);
    setLabel(task.name == task.origin ? '' : task.name);
  }, [ task ]);

  useEffect(() => {
    const data = { ...task };

    data.start = start;
    data.label = label;
    data.duration = duration;
    data.end = end;
    data.resources = resources;

    setReqData(data);
  }, [ resources, start, duration, end, label ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (holidays.includes(start)) {
      notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
      return;
    } else if (holidays.includes(end)) {
      notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
      return;
    }

    setGlobalLoading("Aggiornamento Task...");

    req('sync-task', { task: reqData }).then(async (res) => {
      if (res.success) {
        setTasks(tasks => tasks.map(t => t.id == task.id ? { ...reqData, moved: 1, name: reqData.label || task.origin } : t));
      } else {
        notify(res.error, "Errore durante l'aggiornamento del task.", 'error');
      }

      setGlobalLoading(false);
    });

    onClose();
  };

  return (
    <Formify className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <Formify.Title className="w-full font-semibold border-b border-gray-400">Modifica Task</Formify.Title>

      <Flexi.Col to="5" className="space-y-1 pb-2">
        <Formify.Group>
          <Formify.Label>Titolo</Formify.Label>
          <Formify.Input
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            value={ label }
            maxLength={ 50 }
            onInput={ (val) => task.record === 'ST' && setLabel(val) }
            placeholder={ task.origin }
            disabled={ task.record != "ST" }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>Data Inizio</Formify.Label>
          <Formify.Input required
            type="date"
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b"
            value={ start }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Inizio', e.target.validationMessage, 'error');
            } }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setStart(val);

                if (new Date(val) != 'Invalid Date' && new Date(val) > new Date('2000-01-01')) {
                  setEnd(Moment(val).end(duration / workingHours, holidays));
                }
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>Data Fine</Formify.Label>
          <Formify.Input required
            type="date"
            min={ start }
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            value={ end }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Fine', e.target.validationMessage, 'error');
            } }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setEnd(val);
                setDuration(Moment(start).duration(val, holidays) * workingHours);
              } else {
                notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>Durata (Ore)</Formify.Label>
          <Formify.Input
            required
            type="number"
            min={ 0 }
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            value={ +duration }
            onInput={ (val) => {
              setDuration(+val);

              setEnd(Moment(start).end(+val / workingHours, holidays));
            } }
          />
        </Formify.Group>

        <Formify.Group className="flex flex-col">
          <Formify.Label className="">Numero Persone</Formify.Label>
          <Formify.Input required
            min={ 1 }
            step={ 1 }
            type="number"
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Errore', e.target.validationMessage, 'error');
            } }
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b"
            value={ resources }
            onInput={ (val) => setResources(+val) }
          />
        </Formify.Group>

        <Formify.Button>Salva</Formify.Button>
      </Flexi.Col>
    </Formify>
  );
};

export default TaskFormEdit;