import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './app';
import { LanguageProvider } from './app/components/Language';
import { AuthProvider } from './app/components/Auth';
import { NotifyProvider } from './app/components/Notify';
import { ContextMenuProvider } from './components/ContextMenu';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LanguageProvider>
    <NotifyProvider>
      <ContextMenuProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ContextMenuProvider>
    </NotifyProvider>
  </LanguageProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
