import { useEffect, useState } from "react";
import { useNotify } from "../../../components/Notify";
import req from "../../../../modules/req";

function useParameters() {
  const { notify } = useNotify();

  const [ data, setData ] = useState(undefined);
  const [ isLoading, setIsLoading ] = useState(true);

  const sync = async () => {
    const res = await req("get-parameters");

    if (res.success) {
      const newData = res.data.reduce((acc, param) => {
        acc[ param.PARAMETRO ] = param.VALORE;
        return acc;
      }, {});

      setData(newData);
    } else {
      notify(res.error, "Errore nel caricamento degli impostazioni.", 'error');
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    sync();
  }, []);

  return {
    data,
    setData: async (parameter, value) => {
      setData(curr => ({ ...curr, [ parameter ]: value }));
      req("set-parameter", { parameter, value });
    },
    sync,
    isLoading
  };
}

export default useParameters;