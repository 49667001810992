import { useState } from "react";
import { ContextureButton, Flaticon, Modality } from "../../../../components/WePack";
import req from "../../../../modules/req";
import { useNotify } from "../../../components/Notify";


const TaskContextMenu = ({
  reqFrom,
  task,
  setTasks,
  onClose,
  setGlobalLoading,
  syncTasks,
  onTaskEdit,
  onSplitEdit,
  onCut,
  onMilestonEdit
}) => {
  const { notify } = useNotify();

  const [ showDeleteConfirm, setShowDeleteConfirm ] = useState(false);

  const handleEditTask = () => {
    onClose();
    onTaskEdit();
  };

  const handleSplitEdit = () => {
    onClose();
    onSplitEdit();
  };

  const handleMilestoneEdit = () => {
    onMilestonEdit();
    onClose();
  };

  const handleTaskSplit = () => {
    onClose();
    onCut();
  };

  const handleDelete = async () => {
    onClose();
    req('del-subtask', { id: task.id });
    setTasks(tasks => tasks.filter(t => t.id !== task.id));
    setShowDeleteConfirm(false);
  };

  const handleCreate = async () => {
    onClose();
    setGlobalLoading("Creazione in corso...");
    req('new-subtask', { from: task.id }).then(async (res) => {
      if (res.success) {
        await syncTasks(false);
      }
      setGlobalLoading(false);
    });
  };

  const handleFolder = () => {
    onClose();
    setTasks(tasks => tasks.map(t => {
      if (t.id === task.id) {
        return {
          ...t,
          showChilds: false
        };
      } else if (t.position.startsWith(task.position + '.')) {
        return {
          ...t,
          showChilds: false
        };
      } else {
        return t;
      }
    }));
  };

  const handleExpand = () => {
    onClose();
    setTasks(tasks => tasks.map(t => {
      if (t.id === task.id) {
        return {
          ...t,
          showChilds: true
        };
      } else if (t.position.startsWith(task.position + '.')) {
        return {
          ...t,
          showChilds: true
        };
      } else {
        return t;
      }
    }));
  };

  const handleConvertToMilestone = () => {
    req('sync-task', { task: { ...task, record: 'M' } }).then(async (res) => {
      if (res.error) {
        notify('Errore', "Errore durante la conversione in Milestone.", 'error');
      }
    });
    setTasks(tasks => tasks.map(t => t.id === task.id ? { ...task, date: task.start, type: 'milestone', record: 'M' } : t));
    onClose();
  };

  const handleConvertToTask = () => {
    req('sync-task', { task: { ...task, record: 'ST' } }).then(async (res) => {
      if (res.error) {
        notify('Errore', "Errore durante la conversione in Milestone.", 'error');
      }
    });
    setTasks(tasks => tasks.map(t => t.id === task.id ? { ...task, date: undefined, type: 'task', record: 'ST' } : t));
    onClose();
  };

  return (<>
    <Modality
      className="bg-white rounded-md shadow-xl"
      show={ showDeleteConfirm }
      onClose={ () => { onClose(); setShowDeleteConfirm(false); } }
    >
      <Modality.Header>Attenzione</Modality.Header>
      <Modality.Body>Sei sicuro di voler eliminare questo task?</Modality.Body>
      <Modality.Footer>
        <Modality.Button
          onClick={ () => { onClose(); setShowDeleteConfirm(false); } }
          className="bg-white hover:bg-gray-100 border-gray-200 hover:border-gray-300 rounded-md"
        >
          Chiudi
        </Modality.Button>
        <Modality.Button
          onClick={ handleDelete }
          className="bg-red-600 hover:bg-red-500 border-red-400 hover:border-red-300 text-white rounded-md"
        >
          Elimina
        </Modality.Button>
      </Modality.Footer>
    </Modality>

    { /* MODIFICA IL TASK */ }
    { [ 'T', 'ST', 'ODP' ].includes(task.record) && task.type == 'task' && !task.split && (
      <ContextureButton onClick={ handleEditTask }>
        <Flaticon name="edit" type="rr" className="w-4 h-4" />
        <p>Modifica Task</p>
      </ContextureButton>
    ) }

    { /* MODIFICA IL MILESTONE */ }
    { [ 'M' ].includes(task.record) && task.type == 'milestone' && (
      <ContextureButton onClick={ handleMilestoneEdit }>
        <Flaticon name="edit" type="rr" className="w-4 h-4" />
        <p>Modifica Milestone</p>
      </ContextureButton>
    ) }

    { task.split && (
      <ContextureButton onClick={ handleSplitEdit }>
        <Flaticon name="edit" type="rr" className="w-4 h-4" />
        <p>Modifica Split</p>
      </ContextureButton>
    ) }

    { !task.split && [ 'ST' ].includes(task.record) && task.type == 'task' && (
      <ContextureButton onClick={ handleConvertToMilestone }>
        <Flaticon name="hourglass-start" type="rr" className="w-4 h-4" />
        <p>Converti in Milestone</p>
      </ContextureButton>
    ) }

    { [ 'M' ].includes(task.record) && task.type == 'milestone' && (
      <ContextureButton onClick={ handleConvertToTask }>
        <Flaticon name="hourglass-start" type="rr" className="w-4 h-4" />
        <p>Converti in Task</p>
      </ContextureButton>
    ) }

    { /* TAGLIA IL TASK */ }
    { [ 'T', 'ODP', 'ST' ].includes(task.record) && task.type == 'task' && reqFrom == 'task' && (
      <ContextureButton onClick={ handleTaskSplit }>
        <Flaticon name="scissors" type="rr" className="w-4 h-4" />
        <p>Taglia</p>
      </ContextureButton>
    ) }

    { /* CREA UN SUB-TASK */ }
    { [ 'T' ].includes(task.record) && (
      <ContextureButton onClick={ handleCreate }>
        <Flaticon name="square-plus" type="rr" className="w-4 h-4" />
        <p>Aggiungi attività</p>
      </ContextureButton>
    ) }

    { /* COPIA L'ORDINE PRODUZIONE */ }
    { task.orderCode && (
      <ContextureButton
        onClick={ () => {
          navigator.clipboard.writeText(task.orderCode);
          notify('Errore', "Ordine Produzione copiato negli appunti.", 'error');
          onClose();
        } }
      >
        <Flaticon name="copy-alt" type="rr" className="w-4 h-4" />
        <p>Copia Ordine Produzione</p>
      </ContextureButton>
    ) }

    { /* COPIA CODICE ARTICOLO */ }
    { task.articleCode && (
      <ContextureButton
        onClick={ () => {
          navigator.clipboard.writeText(task.articleCode);
          notify('Errore', "Codice articolo copiato negli appunti.", 'error');
          onClose();
        } }
      >
        <Flaticon name="copy-alt" type="rr" className="w-4 h-4" />
        <p>Copia Codice Articolo</p>
      </ContextureButton>
    ) }

    { /* ELIMINA IL SUB-TASK */ }
    { [ 'ST', 'M' ].includes(task.record) && (
      <ContextureButton onClick={ () => setShowDeleteConfirm(true) }>
        <Flaticon name="trash" type="rr" className="w-4 h-4" />
        <p>Elimina { task.record === 'ST' ? 'Task' : 'Milestone' }</p>
      </ContextureButton>
    ) }

    { /* ESPANDI O NASCONDI I SOTTO TASK */ }
    { task.showChilds !== undefined && (<>
      <ContextureButton onClick={ handleFolder }>
        <Flaticon name="angle-right" type="rr" className="w-4 h-4" />
        <p>Comprimi tutti</p>
      </ContextureButton>

      <ContextureButton onClick={ handleExpand }>
        <Flaticon name="angle-down" type="rr" className="w-4 h-4" />
        <p>Espandi tutti</p>
      </ContextureButton>
    </>) }

  </>);
};


export default TaskContextMenu;