import { useEffect, useState } from "react";
import { useNotify } from "../../../components/Notify";
import Moment from "../../../../components/Moment";
import req from "../../../../modules/req";
import { Flexi, Formify } from "../../../../components/WePack";


const CutForm = ({ task, setTasks, onClose, holidays }) => {
  const { notify } = useNotify();

  const min = task.split?.start ?? task.start;
  const max = task.split?.end ?? task.end;

  const [ from, setFrom ] = useState(task.selectedDate);
  const [ to, setTo ] = useState(task.selectedDate);

  useEffect(() => {
    let availableFrom = Moment(task.selectedDate).add(-1).value();
    let availableTo = Moment(task.selectedDate).add(1).value();

    while (holidays.includes(availableFrom)) {
      availableFrom = Moment(availableFrom).add(-1).value();
    }

    while (holidays.includes(availableTo)) {
      availableTo = Moment(availableTo).add(1).value();
    }

    setFrom(availableFrom);
    setTo(availableTo);
  }, [ task ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    req("new-split", { id: task.split?.id ?? task.id, father: task.id, from, to })
      .then((res) => {
        if (res.success) {
          setTasks((tasks) => tasks.map(t => {
            if (t.id === task.id) {
              return {
                ...t,
                dates: res.data.map(st => {
                  return {
                    ...st,
                    isDraggable: true,
                    isResizable: true
                  };
                })
              };
            } else {
              return t;
            }
          }));
        } else {
          notify(res.error, "Errore durante la divisione del task.", 'error');
        }
      });
    onClose();
  };

  return (
    <Formify className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <Formify.Title className="w-full font-semibold border-b border-gray-400">
        Modifica Task
      </Formify.Title>

      <Flexi.Col to="5" className="space-y-1 pb-2">
        <Formify.Group>
          <Formify.Label>
            Taglio Dal
          </Formify.Label>

          <Formify.Input
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            type="date"
            value={ from }
            onInput={ (val) => setFrom(val) }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Taglio Dal', e.target.validationMessage, 'error');
            } }
            max={ Moment(to).add(-1).value() }
            min={ Moment(min).value() }
          />
        </Formify.Group>

        <Formify.Group>
          <Formify.Label>
            Taglio Al
          </Formify.Label>

          <Formify.Input
            className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200"
            type="date"
            value={ to }

            onInput={ (val) => setTo(val) }
            max={ Moment(max).value() }
            min={ Moment(from).add(1).value() }
          />
        </Formify.Group>

        <Formify.Button>Taglia</Formify.Button>
      </Flexi.Col>
    </Formify>
  );
};

export default CutForm;