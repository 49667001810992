import { useRef, useState } from "react";

export const InputRemake = ({ onInput, ...props }) => {
  return (<input
    { ...props }
    onInput={ (e) => onInput && onInput(e.target.value) }
  />);
}

export const ButtonRemake = ({ onClick, children, tooltip, onMouseEnter, onMouseLeave, onMouseMove, className, ...props }) => {
  const [ showTooltip, setShowTooltip ] = useState(false);
  const [ pos, setPos ] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);
  const timeout = useRef(null);

  const mouseEnter = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => { setShowTooltip(true); }, 300);
  }

  const mouseMove = (e) => {
    if (((e.clientX + 10) + tooltipRef.current.clientWidth) > window.innerWidth) {
      setPos({ x: e.clientX - tooltipRef.current.clientWidth, y: e.clientY + 15 });
    } else {
      setPos({ x: e.clientX + 10, y: e.clientY + 15 });
    }
  }

  const mouseLeave = () => {
    clearTimeout(timeout.current);
    setShowTooltip(false);
  }

  return (<button
    { ...props }
    className={ [ "relative", className ].join(" ") }
    onClick={ onClick }
    onMouseEnter={ (e) => { tooltip && mouseEnter(e); onMouseEnter && onMouseEnter(e); } }
    onMouseMove={ (e) => { tooltip && mouseMove(e); onMouseMove && onMouseMove(e); } }
    onMouseLeave={ (e) => { tooltip && mouseLeave(e); onMouseLeave && onMouseLeave(e); } }
  >
    { children }
    { tooltip && (
      <span
        ref={ tooltipRef }
        className={
          [
            (showTooltip ? "flex" : "hidden"),
            "fixed",
            "whitespace-nowrap",
            "bg-gray-900/80",
            "backdrop-blur-xl",
            "text-white",
            "text-xs",
            "px-1",
            "py-0.5",
            "rounded-md",
            "shadow-md"
          ].join(" ")
        }
        style={ {
          top: pos.y,
          left: pos.x
      } }
      >
        { tooltip }
      </span>
    ) }
  </button>);
}

export const useStateRemake = (initialValue) => {
  const [ data, setData ] = useState(initialValue);

  const add = (newValue) => {
    if (data instanceof Object) {
      setData({ ...data, ...newValue });
    } else if (data instanceof Array) {
      setData([ ...data, ...newValue ]);
    }
  }

  const remove = (key) => {
    if (data instanceof Array) {
      setData(data.filter((val) => val !== key));
    } else if (data instanceof Object) {
      const newData = { ...data };
      delete newData[ key ];
      setData(newData);
    }
  }

  const set = (newValue) => {
    setData(newValue);
  }

  return { data, set, add, remove };
}