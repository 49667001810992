import { useEffect, useMemo, useState } from "react";
import WeGantt from "../../../components/WeGantt";
import req from "../../../modules/req";
import { ContexturePanel, Loady } from "../../../components/WePack";
import { clsx } from "../../../modules/Utils";
import TimesheetPopup from "./components/TimesheetPopup";
import MilestonePopup from "./components/MilestonePopup";
import SettingsPanel from "./components/SettingsPanel";
import FilterPanel from "./components/FilterPanel";
import { useNotify } from "../../components/Notify";
import MilestoneCell from "./components/MilestoneCell";
import TaskCell from "./components/TaskCell";
import TimesheetCell from "./components/TimesheetCell";
import TableRow from "./components/TableRow";
import TaskContextMenu from "./components/TaskContextMenu";
import CutForm from "./components/CutForm";
import SplitEditForm from "./components/SplitEditForm";
import TaskFormEdit from "./components/TaskFormEdit";
import MilestoneFormEdit from "./components/MilestoneFormEdit";
import useTasks from "./hooks/useTasks";
import useHolidays from "./hooks/useHolidays";
import useParameters from "./hooks/useParameters";

const CELL_HEIGHT = 25;
const CELL_WIDTH = 20;
const FONT_SIZE = 13;
const WORKING_HOURS = 8;

export default function TacticalPlanning({ setFloatPanel, setGlobalLoading, globalLoading }) {
  const { notify } = useNotify();

  const parameters = useParameters();
  const tasks = useTasks({ parameters: parameters.data });
  const holidays = useHolidays();
  const [ viewType, setViewType ] = useState('days');
  const finedTasks = useMemo(() => {
    return tasks.data?.map((task) => ({
      ...task,
      color: task.record == 'COM' ? (
        parameters.data?.commessaTaskColor || '#3b82f6'
      ) : [ 'ST', 'T' ].includes(task.record) ? (
        parameters.data?.faseTaskColor || '#22c55e'
      ) : [ 'ODP' ].includes(task.record) && (
        parameters.data?.grpModificabileTaskColor || '#f59e0b'
      ),
      dates: task.dates && task.dates.length > 0 && task.dates.map((date) => ({
        ...date,
        isDraggable: true,
        isResizable: true
      })),
      timesheets: task.timesheets && task.timesheets.length > 0 && task.timesheets.map((tm) => {
        return {
          ...tm,
          color: tm.amountOfResources > 2 ? (
            parameters.data?.timesheetHighColor || '#999999'
          ) : tm.amountOfResources > 1 ? (
            parameters.data?.timesheetMediumColor || '#c4c4c4'
          ) : (
            parameters.data?.timesheetLowColor || '#e3e3e3'
          )
        };
      }),
      className: clsx(
        "border border-gray-100 scale-75 w-3 h-3",
        task.orderType == 'RD' && "rounded-full",
        task.orderType == 'CH' && "rotate-45",
        task.orderType == 'CL' && "rotate-0",
        task.confirmedDeliveryDate != undefined ? "bg-green-400" : "bg-red-600"
      )
    }));
  }, [ tasks.data, parameters.data ]);

  const [ showFilterPanel, setShowFilterPanel ] = useState(false);
  const [ showSettingsPanel, setShowSettingsPanel ] = useState(false);
  const [ showTaskForm, setShowTaskForm ] = useState({ show: false, posX: 0, posY: 0, task: undefined });
  const [ showTaskContextMenu, setShowTaskContextMenu ] = useState({ show: false, posX: 0, posY: 0, task: undefined, split: undefined, reqFrom: 'table' });
  const [ showTaskSplitForm, setShowCutForm ] = useState({ show: false, posX: 0, posY: 0, task: undefined });
  const [ showSplitEditForm, setShowSplitEditForm ] = useState({ show: false, posX: 0, posY: 0, task: undefined, split: undefined });
  const [ showMilestoneFormEdit, setShowMilestoneFormEdit ] = useState({ show: false, posX: 0, posY: 0, task: undefined });

  // ON TASK MOVE HANDLE
  const handleMove = async (task) => {
    req("sync-task", { task })
      .then((res) => {
        if (res.error) {
          notify(res.error, "Errore sincronizzazione task", 'error');
        }
      });
    tasks.setData(tasks => tasks.map((t) => t.id == task.id ? { ...task, moved: 1 } : t));
  };

  // ON TASK RESIZE HANDLE
  const handleResize = async (task) => {
    req("sync-task", { task })
      .then((res) => {
        if (res.error) {
          notify(res.error, "Errore sincronizzazione task", 'error');
        }
      });
    tasks.setData(tasks => tasks.map((t) => t.id == task.id ? { ...task, moved: 1 } : t));
  };

  // ADD FLOAT PANEL ACTIONS
  useEffect(() => {
    setFloatPanel([
      { label: "Aggiorna", icon: { type: 'rr', icon: 'refresh' }, onClick: () => tasks.resync() },
      { label: "Filtri", icon: { type: 'rr', icon: 'filter' }, onClick: () => setShowFilterPanel(true) },
      { label: "Impostazioni", icon: { type: 'rr', icon: 'settings' }, onClick: () => setShowSettingsPanel(true) }
    ]);
  }, []);

  return (
    <div className="w-full h-full">
      <FilterPanel
        show={ showFilterPanel }
        onClose={ () => setShowFilterPanel(false) }
        setViewType={ setViewType }
        viewType={ viewType }
        selectedCommessa={ tasks.project }
        onSubmit={ ({ selectedCommessa }) => tasks.setProject(selectedCommessa) }
      />

      <SettingsPanel
        cellHeight={ parameters.data?.cellHeight || CELL_HEIGHT }
        cellWidth={ CELL_WIDTH }
        fontSize={ FONT_SIZE }
        show={ showSettingsPanel }
        onClose={ () => setShowSettingsPanel(false) }
        parameters={ parameters.data }
        setParameter={ (parameter, value) => {
          if (!(parameters.data?.[ parameter ] === value)) {
            parameters.setData(parameter, value);
          }
        } }
      />

      { globalLoading && <div className="w-full h-full absolute bg-transparent z-[999]" /> }

      <Loady
        className="w-full h-full flex justify-center items-center"
        color="#55b6c9"
        show={ holidays.isLoading || tasks.isLoading || parameters.isLoading }
        label="Caricamento Gantt..."
        size="40px"
      >
        { !tasks.project || !finedTasks ? (
          <div className="w-full h-36 flex justify-center items-center text-xl">
            Cerca una commessa valida per visualizzare il Gantt...
          </div>
        ) : (<>
          <ContexturePanel simple
            show={ showTaskForm.show }
            onClose={ () => setShowTaskForm({ ...showTaskForm, show: false }) }
            position={ { x: showTaskForm.posX, y: showTaskForm.posY } }
          >
            <TaskFormEdit
              holidays={ holidays.data }
              task={ showTaskForm.task }
              workingHours={ WORKING_HOURS }
              setTasks={ tasks.setData }
              setGlobalLoading={ setGlobalLoading }
              onClose={ () => setShowTaskForm({ ...showTaskForm, show: false }) }
            />
          </ContexturePanel>

          <ContexturePanel simple
            show={ showTaskSplitForm.show }
            onClose={ () => setShowCutForm({ ...showTaskSplitForm, show: false }) }
            position={ { x: showTaskSplitForm.posX, y: showTaskSplitForm.posY } }
          >
            <CutForm
              holidays={ holidays.data }
              task={ showTaskSplitForm.task }
              workingHours={ WORKING_HOURS }
              setTasks={ tasks.setData }
              setGlobalLoading={ setGlobalLoading }
              onClose={ () => setShowCutForm({ ...showTaskSplitForm, show: false }) }
            />
          </ContexturePanel>

          <ContexturePanel simple
            show={ showTaskContextMenu.show }
            onClose={ () => setShowTaskContextMenu({ ...showTaskContextMenu, show: false }) }
            position={ { x: showTaskContextMenu.posX, y: showTaskContextMenu.posY } }
          >
            <TaskContextMenu
              task={ showTaskContextMenu.task }
              setTasks={ tasks.setData }
              syncTasks={ tasks.resync }
              setGlobalLoading={ setGlobalLoading }
              workingHours={ WORKING_HOURS }
              reqFrom={ showTaskContextMenu.reqFrom }
              onClose={ () => setShowTaskContextMenu({ ...showTaskContextMenu, show: false }) }
              onTaskEdit={ () => {
                setShowTaskForm({
                  show: true,
                  posX: showTaskContextMenu.posX,
                  posY: showTaskContextMenu.posY,
                  task: showTaskContextMenu.task
                });
              } }
              onCut={ () => {
                setShowCutForm({
                  show: true,
                  posX: showTaskContextMenu.posX,
                  posY: showTaskContextMenu.posY,
                  task: showTaskContextMenu.task
                });
              } }
              onSplitEdit={ () => {
                setShowSplitEditForm({
                  show: true,
                  posX: showTaskContextMenu.posX,
                  posY: showTaskContextMenu.posY,
                  task: showTaskContextMenu.task,
                  split: showTaskContextMenu.task.split
                });
              } }
              onMilestonEdit={ () => {
                setShowMilestoneFormEdit({
                  show: true,
                  posX: showTaskContextMenu.posX,
                  posY: showTaskContextMenu.posY,
                  task: showTaskContextMenu.task
                });
              } }
            />
          </ContexturePanel>

          <ContexturePanel simple
            show={ showSplitEditForm.show }
            onClose={ () => setShowSplitEditForm({ ...showSplitEditForm, show: false }) }
            position={ { x: showSplitEditForm.posX, y: showSplitEditForm.posY } }
          >
            <SplitEditForm
              task={ showSplitEditForm.task }
              split={ showSplitEditForm.split }
              setTasks={ tasks.setData }
              holidays={ holidays.data }
              setGlobalLoading={ setGlobalLoading }
              workingHours={ WORKING_HOURS }
              onClose={ () => setShowSplitEditForm({ ...showSplitEditForm, show: false }) }
            />
          </ContexturePanel>

          <ContexturePanel simple
            show={ showMilestoneFormEdit.show }
            onClose={ () => setShowMilestoneFormEdit({ ...showMilestoneFormEdit, show: false }) }
            position={ { x: showMilestoneFormEdit.posX, y: showMilestoneFormEdit.posY } }
          >
            <MilestoneFormEdit
              task={ showMilestoneFormEdit.task }
              setTasks={ tasks.setData }
              setGlobalLoading={ setGlobalLoading }
              holidays={ holidays.data }
              workingHours={ WORKING_HOURS }
              onClose={ () => setShowMilestoneFormEdit({ ...showMilestoneFormEdit, show: false }) }
            />
          </ContexturePanel>

          <WeGantt
            cellHeight={ (parameters.data?.cellHeight || CELL_HEIGHT) * (parameters.data?.sizeMultipler || 0.9) }
            cellWidth={ CELL_WIDTH * (parameters.data?.sizeMultipler || 0.9) }
            fontSize={ FONT_SIZE * (parameters.data?.sizeMultipler || 0.9) }
            tasks={ finedTasks }
            holidays={ holidays.data ?? [] }
            displayHolidays={ holidays.data ?? [] }
            styles={ {
              holidayColor: parameters.data?.holidayColor || '#f0f0f0',
              todayColor: parameters.data?.todayColor || '#ff0000'
            } }
            workingHours={ WORKING_HOURS }
            viewMode={ viewType }

            onMove={ handleMove }
            onResize={ handleResize }

            onTaskDoubleClick={ ({ task, event }) => {
              if (task.type != 'project')

                if (task.split) {
                  setShowSplitEditForm({ show: true, posX: event.clientX, posY: event.clientY, task, split: task.split });
                } else {
                  setShowTaskForm({ show: true, posX: event.clientX, posY: event.clientY, task });
                }
            } }

            onTaskContextMenu={ ({ task, event }) => {
              setShowTaskContextMenu({ show: true, posX: event.clientX, posY: event.clientY, task, reqFrom: 'task' });
            } }

            onMilestoneHover={ ({ task }) => <MilestonePopup task={ task } /> }

            onTimesheetHover={ ({ timesheet }) => {
              return (
                <TimesheetPopup
                  amountOfResources={ timesheet.amountOfResources }
                  amountOfHours={ timesheet.hours }
                  date={ timesheet.date.split('-').reverse().join('/') }
                  resources={ timesheet.details }
                />
              );
            } }

            TimesheetCell={ (timesheet) => (
              <TimesheetCell
                sizeMultipler={ parameters.data?.sizeMultipler || 0.9 }
                fontSize={ FONT_SIZE }
                { ...timesheet }
              />
            ) }

            TableColumns={ () => {
              return [
                <div className="w-full flex px-2">Nome</div>,
                <>Ore Budg.</>,
                <>Ore Cons.</>,
                <>Budg. Res.</>,
                <>Ore a finire</>
              ];
            } }

            TableRow={ ({ task }) => {
              return (
                <TableRow
                  fontSize={ FONT_SIZE * (parameters.data?.sizeMultipler || 0.9) }
                  cellHeight={ (parameters.data?.cellHeight || CELL_HEIGHT) * (parameters.data?.sizeMultipler || 0.9) }
                  sizeMultipler={ parameters.data?.sizeMultipler || 0.9 }
                  task={ task }
                  setTasks={ tasks.setData }
                  holidays={ holidays.data }
                  onContextMenu={ ({ event }) => {
                    setShowTaskContextMenu({ show: true, posX: event.clientX, posY: event.clientY, task, reqFrom: 'table' });
                  } }
                />
              );
            } }

            MilestoneCell={ ({ task, from }) => {
              return (
                <MilestoneCell
                  from={ from }
                  fontSize={ FONT_SIZE * (parameters.data?.sizeMultipler || 0.9) }
                  cellWidth={ CELL_WIDTH * (parameters.data?.sizeMultipler || 0.9) }
                  { ...task }
                />
              );
            } }

            TaskCell={ ({ task, splitTask }) => {
              return (
                <TaskCell
                  task={ task }
                  splitTask={ splitTask }
                />
              );
            } }
          />
        </>) }
      </Loady>
    </div>
  );
}