import React, { createContext, useContext, useState } from 'react';
import DICTIONARY from '../../assets/language';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('IT');

  const changeLanguage = (newLanguage) => {
    if(!DICTIONARY[newLanguage]) {
      return console.error('Language ' + newLanguage + ' not found in dictionary');
    }

    setLanguage(newLanguage);
  }

  return (
    <LanguageContext.Provider value={{ DICTIONARY: DICTIONARY[language], changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, useLanguage };