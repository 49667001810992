import secureLocalStorage from 'react-secure-storage';
import config from '../assets/config.json';

export default async function req(url, data = {}) {
  const token = secureLocalStorage.getItem('token');

  const res = await fetch(config.backend, {
    method: 'POST',
    body: JSON.stringify({ url, token, data })
  }).then(res => {
    if(res.status === 200) {
      return { code: 200, data: res.json() ?? null };
    } else {
      return { code: res.status, error: res.statusText };
    }
  }).catch(error => ({ code: 600, error }));

  if (res.code === 200) {
    if (res.data) {
      const body = await res.data.then(res => res).catch(err => ({ error: 'json' }));
      if (body.status === "success") {
        return { success: true, data: body.body };
      } else if (body.status === "error") {
        return { error: body.error };
      } else {
        return { error: 'status' };
      }
    } else {
      return { error: 'response' };
    }
  } else if (res.code >= 100 && res.code < 200) {
    return { error: 'info' };
  } else if (res.code >= 300 && res.code < 400) {
    return { error: 'redirect' };
  } else if (res.code >= 400 && res.code < 500) {
    return { error: 'client' };
  } else if (res.code >= 500 && res.code < 600) {
    return { error: 'server' };
  } else {
    return { error: res.error };
  }
}