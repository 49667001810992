import { useEffect, useRef, useState } from "react";
import req from "../../../../modules/req";
import { useNotify } from "../../../components/Notify";

const useBollature = () => {
  const [ bollature, setBollature ] = useState(undefined);
  const [ loadingBollature, setLoadingBollature ] = useState(true);
  const [ lastUpdateBollature, setLastUpdateBollature ] = useState(undefined);
  const syncInterval = useRef(null);
  const { notify } = useNotify();

  const syncBollature = async () => {
    setLoadingBollature(true);
    setLastUpdateBollature(undefined);
    const { success, data, error } = await req('get-bollature');

    if(success) {
      setLastUpdateBollature(new Date().toISOString().split('T')[0].split('-').reverse().join('/') + ' ' + new Date().toTimeString().split(' ')[0]);
      setBollature(data);
    } else {
      notify(`[${error}]`, "Errore nel caricamento delle bollature", 'error');
    } 

    setLoadingBollature(false);
  }

  useEffect(() => {
    syncBollature();

    syncInterval.current = setInterval(() => {
      syncBollature();
    }, 1000 * 60 * 10);

    return () => {
      clearInterval(syncInterval.current)
    }
  }, []);

  return { bollature, loadingBollature, syncBollature, lastUpdateBollature }
}

export default useBollature;