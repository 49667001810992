import { useEffect, useRef, useState } from "react";
import { ContextureButton, ContextureCategory, ContexturePanel, Flaticon, Formify, Radiant } from "../../../../components/WePack";
import sls from "react-secure-storage";
import { clsx } from "../../../../modules/Utils";
import req from "../../../../modules/req";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";

const POS_X = 178;
const POS_Y = 40;

const VIEWMODE_OPTIONS = [
  { label: 'Settimana', value: 'weeks' },
  { label: 'Giorno', value: 'days' }
];

const FilterPanel = ({ show, onClose, selectedCommessa, onSubmit, setViewType, viewType }) => {
  const [ data, setData ] = useState({ selectedCommessa });




  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(data);
    sls.setItem("tactical-planning-selected-commessa", data.selectedCommessa);
    onClose();
  };

  useEffect(() => {
    setData(d => ({ ...d, selectedCommessa }));
  }, [ selectedCommessa ]);

  const handleClose = () => {
    setData({ selectedCommessa });
    onClose();
  };

  const timeoutViewType = useRef(undefined);
  const [ showViewType, setShowViewType ] = useState(false);

  return (
    <ContexturePanel simple
      onClose={ handleClose }
      show={ show }
      position={ { x: POS_X, y: POS_Y } }
    >
      { /** Visualizza per: settimana, giorno */ }
      <ContextureCategory label="Generali">
        <div className="flex justify-start items-center px-1 py-0.5 w-full space-x-2 text-nowrap">
          <ContextureButton
            onMouseEvent={ () => () => {
              if (timeoutViewType.current) {
                clearTimeout(timeoutViewType.current);
              };

              timeoutViewType.current = setTimeout(() => {
                setShowViewType(true);
              }, 500);
            } }
            onMouseLeave={ () => {
              if (timeoutViewType.current) {
                clearTimeout(timeoutViewType.current);
              }
            } }
            onClick={ () => {
              if (timeoutViewType.current) {
                clearTimeout(timeoutViewType.current);
              }

              setShowViewType(true);
            } }
          >
            <Flaticon name="calendar" type="rr" className="w-4 h-4" />

            <span className="text-gray-800 text-start flex-1">
              Visualizzazione
            </span>

            <Flaticon name="angle-right" type="rr" size={ 11 } />
          </ContextureButton>
        </div>
      </ContextureCategory>

      { /** Numero Commessa */ }
      <ContextureCategory label="Commessa">
        <div className="flex justify-start items-center px-1 py-0.5 w-full space-x-2 text-nowrap">
          <SearchByCommessa
            value={ data.selectedCommessa }
            onChange={ (val) => setData(curr => ({ ...curr, selectedCommessa: val })) }
          />
        </div>
      </ContextureCategory>



      <div className="flex items-center justify-end w-full pt-2">
        <button
          onClick={ handleSubmit }
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-2 duration-75 bg-green-500 hover:bg-green-400",
            "border-green-400 hover:border-green-300 text-white"
          ) }
        >
          Applica
        </button>
      </div>

      <ContexturePanel simple outClose
        show={ showViewType }
        position={ { x: POS_X + 210, y: POS_Y + 30 } }
        onClose={ () => setShowViewType(false) }
      >
        <Radiant value={ viewType } onChange={ setViewType }>
          { VIEWMODE_OPTIONS.map((option) => (
            <Radiant.Option
              key={ option.value }
              value={ option.value }
            >
              { ({ checked }) => (
                <ContextureButton addClasses={ checked && "bg-green-200 hover:bg-green-200" }>
                  <Flaticon name="check" type="rr" className={ clsx("h-4 w-4", !checked && "invisible") } />

                  <span className="flex space-x-2">
                    { option.label }
                  </span>
                </ContextureButton>
              ) }
            </Radiant.Option>
          )) }
        </Radiant>
      </ContexturePanel>
    </ContexturePanel>
  );
};

const SearchByCommessa = ({ onChange, value }) => {
  const [ suggs, setSuggs ] = useState(sls.getItem("tactical-planning-commesse") ?? []);
  const [ query, setQuery ] = useState('');

  const fetchSuggestions = async () => {
    const res = await req('get-commesse');

    if (res.success) {
      sls.setItem("tactical-planning-commesse", [ 'Tutto', ...res.data.commesse ]);
      setSuggs([ 'Tutto', ...res.data.commesse ]);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const filteredSuggs = query == '' ? suggs : suggs.filter((sugg) => sugg.toLowerCase().includes(query?.toLowerCase()));

  return (
    <div className="relative w-full">
      <Combobox immediate
        value={ value }
        onChange={ onChange }
      >
        <ComboboxInput required
          className="px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b w-full"
          displayValue={ (val) => val }
          onChange={ (e) => setQuery(e.target.value) }
        />

        <ComboboxOptions className="absolute top-full bg-white w-full border divide-y max-h-64 overflow-y-auto">
          { filteredSuggs.sort((a, b) => {
            if (a == 'Tutto' || +a.slice(1) < +b.slice(1)) {
              return -1;
            } else if (b == 'Tutto' || +a.slice(1) > +b.slice(1)) {
              return 1;
            } else {
              return 0;
            }
          }).map((sugg) => (
            <ComboboxOption
              key={ sugg }
              value={ sugg }
              className="select-none cursor-pointer w-full flex data-[selected]:bg-green-200 data-[focus]:bg-gray-200 px-2 space-x-2"
            >
              { ({ focus, selected }) => (
                <>
                  <Flaticon
                    className={ !selected && 'invisible' }
                    name="check"
                    type="rr"
                    size={ 11 }
                  />

                  <span>
                    { sugg }
                  </span>
                </>
              ) }
            </ComboboxOption>
          )) }
        </ComboboxOptions>
      </Combobox>

    </div>
  );
};

export default FilterPanel;