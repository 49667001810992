import { Flaticon, Flexi, Scripty, Tablify } from "../../../components/WePack";
import useBollature from "./hooks/useBollature";

export default function Dashboard() {
  const { bollature, loadingBollature, syncBollature, lastUpdateBollature } = useBollature();

  return (
    <Flexi.Col to="2">
      <Flexi.Col to="2" className="p-4 max-w-[1920px] w-full">
        <Scripty className="w-full">
          <Scripty.Header className="text-2xl text-black-800 py-2 px-3 border-b border-gray-300 w-full flex justify-between text-start">
            <span className="font-semibold">Attività in corso</span>
            <div className="flex space-x-2 items-center">
              <span className="text-xs">{ lastUpdateBollature ? "Ultimo Aggiornamento " + lastUpdateBollature : "Aggiornamento..." }</span>
              <button className={ loadingBollature && "animate-spin" } disabled={ loadingBollature } onClick={ syncBollature }>
                <Flaticon name="refresh" type="br" className="text-base" />
              </button>
            </div>
          </Scripty.Header>
          <Scripty.Block className="p-2 h-full w-full">
            <div className="bg-white shadow-md rounded-sm overflow-y-auto max-h-[820px] w-full">
              <Tablify className="*:*:*:px-2 w-full text-sm">
                <Tablify.Head>
                  <Tablify.Row className="*:text-start *:py-2 *:bg-white z-10 shadow-md sticky top-0">
                    <Tablify.HeadCell className="max-lg:hidden">Dipendente</Tablify.HeadCell>
                    <Tablify.HeadCell>Cognome Nome</Tablify.HeadCell>
                    <Tablify.HeadCell className="max-sm:hidden">Commessa</Tablify.HeadCell>
                    <Tablify.HeadCell className="max-md:hidden">Ordine di Produzione</Tablify.HeadCell>
                    <Tablify.HeadCell>Articolo</Tablify.HeadCell>
                    <Tablify.HeadCell>Descrizione</Tablify.HeadCell>
                  </Tablify.Row>
                </Tablify.Head>
                <Tablify.Body className="divide-y divide-gray-200">
                  { bollature?.length > 0 ? (
                    bollature?.map((item, i) => (
                      <Tablify.Row key={ i }>
                        <Tablify.Cell className="max-lg:hidden">{ item.DIPENDENTE }</Tablify.Cell>
                        <Tablify.Cell>{ item.COGNOMENOME }</Tablify.Cell>
                        <Tablify.Cell className="max-sm:hidden">{ item.COMMESSA }</Tablify.Cell>
                        <Tablify.Cell className="max-md:hidden">{ item.ORDINEPRODUZIONE }</Tablify.Cell>
                        <Tablify.Cell>{ item.ARTICOLODAPRODURRE }</Tablify.Cell>
                        <Tablify.Cell>{ item.DESCRIZIONEARTICOLO }</Tablify.Cell>
                      </Tablify.Row>
                    ))
                  ) : loadingBollature ? (
                    <Tablify.Row>
                      <Tablify.Cell colSpan="6" className="text-base py-2 text-center">Caricamento Dati...</Tablify.Cell>
                    </Tablify.Row>
                  ) : (
                    <Tablify.Row>
                      <Tablify.Cell colSpan="6" className="text-base py-2 text-center">Nessuna attività in corso.</Tablify.Cell>
                    </Tablify.Row>
                  ) }
                </Tablify.Body>
              </Tablify>
            </div>
          </Scripty.Block>
        </Scripty>
      </Flexi.Col>
    </Flexi.Col>
  );
}